import axios from 'axios';
import { BACKEND_URL } from '../../../../config';

export const LOGIN_URL = `${BACKEND_URL}/auth/login`;
export const LOGOUT = `${BACKEND_URL}/auth/logout`;
export const LINE_LOGIN_URL = `${BACKEND_URL}/auth/login-line`;
export const FACEBOOK_LOGIN_URL = `${BACKEND_URL}/auth/login-facebook`;
export const REGISTER_URL = `${BACKEND_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const ME_URL = `${BACKEND_URL}/auth/me`;
export const SHOP_URL = `${BACKEND_URL}/shop`;
export const SHOP_INVITE = `${BACKEND_URL}/shop/invite`;
export const SHOP_ADMIN_URL = `${BACKEND_URL}/shop/admin`;
const BRANCH_LOGIN_URL = `${BACKEND_URL}/auth/login-branch`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function logout() {
  return axios.post(LOGOUT);
}

export function lineLogin(lineId, shopId, lineProfile, email, alternativeLineId, branchFriendlyId) {
  return axios.post(LINE_LOGIN_URL, { lineId, shopId, lineProfile, email, alternativeLineId, branchFriendlyId });
}

export function facebookLogin(facebookId, accessToken, shopId, inviteCode) {
  return axios.post(FACEBOOK_LOGIN_URL, { facebookId, accessToken, shopId, inviteCode });
}

export function loginToBranch(token, branchId) {
  return axios.post(BRANCH_LOGIN_URL, { branchId }, { headers: { Authorization: `Bearer ${token}` } });
}

export function register({
  firstName,
  lastName,
  phone,
  gender,
  email,
  birthdate,
  lineId,
  alternativeLineId,
  linePictureUrl,
  seqId,
  lineDisplayName,
  lineEmail,
  inviteCode,
  facebookId,
  facebookProfile,
  shippingAddress,
  remark,
  password,
  re_password,
  salesName,
  countryDialCode,
  mgm,
  referral,
  hasConfirmedWhatsapp,
  userReferral,
  branchFriendlyId,
  custom1,
  custom2,
  custom3
}) {
  return axios.post(REGISTER_URL, {
    firstName,
    lastName,
    gender,
    phone,
    email,
    birthdate,
    lineId,
    alternativeLineId,
    linePictureUrl,
    seqId,
    lineDisplayName,
    lineEmail,
    inviteCode,
    facebookId,
    facebookProfile,
    shippingAddress,
    remark,
    password,
    re_password,
    salesName,
    mgm,
    referral,
    countryDialCode,
    hasConfirmedWhatsapp,
    userReferral,
    registeredBranchFriendlyId: branchFriendlyId,
    custom1,
    custom2,
    custom3
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL, { headers: { Authorization: `Bearer ${token}` } });
}

export function createShop(formdata) {
  return axios.post(SHOP_URL, formdata, { headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` } });
}

export function editShop(id, formdata) {
  return axios.patch(`${SHOP_URL}/${id}`, formdata, {
    headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` }
  });
}

export function getShop() {
  return axios.get(SHOP_URL, { headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` } });
}

export function getShopById(id) {
  return axios.get(`${SHOP_URL}/${id}`, { headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` } });
}

export function shopinvite(id) {
  return axios.get(`${SHOP_INVITE}/${id}`, {
    headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` }
  });
}

export function searchShopByName(name) {
  return axios.get(`${SHOP_URL}?name=${name}`, {
    headers: { Authorization: `${process.env.REACT_APP_MANAGEMENT_SHOP_ID}` }
  });
}

export function editShopByAdmin(id, formdata) {
  return axios.patch(`${SHOP_ADMIN_URL}/${id}`, formdata);
}
