import { createSlice } from '@reduxjs/toolkit';

//This slice acts as a local state for simplicity since sms setting is used only in one place in the app,
// different from others that involves fetching requests

const INITIAL_STATE = {
  bookingSms: {
    isEnabled: false,
    bookingPostConfirmation: {
      template: '',
      isEnabled: false
    },
    bookingPostCancellation: {
      template: '',
      isEnabled: false
    }
  },
  shoppingSms: {
    isEnabled: false,
    orderPostConfirmation: {
      template: '',
      isEnabled: false
    },
    orderPostCancellation: {
      template: '',
      isEnabled: false
    }
  }
};

export const smsSettingSlice = createSlice({
  name: 'smsSetting',
  initialState: INITIAL_STATE,
  reducers: {
    setSettingState: (state, action) => {
      return action.payload || INITIAL_STATE;
    },
    toggleService: (state, action) => {
      const { serviceName, checked } = action.payload;
      return {
        ...state,
        [serviceName]: {
          ...state[serviceName],
          isEnabled: checked
        }
      };
    },
    toggleSubService: (state, action) => {
      const { serviceName, subServiceName, checked } = action.payload;

      return {
        ...state,
        [serviceName]: {
          ...state[serviceName],
          [subServiceName]: {
            ...state[serviceName][subServiceName],
            isEnabled: checked
          }
        }
      };
    },
    changeSubServiceTemplate: (state, action) => {
      const { serviceName, subServiceName, text } = action.payload;
      return {
        ...state,
        [serviceName]: {
          ...state[serviceName],
          [subServiceName]: {
            ...state[serviceName][subServiceName],
            template: text
          }
        }
      };
    }
  }
});

export const smsSettingActions = smsSettingSlice.actions;
