import React, { useMemo } from 'react';
import Select from 'react-select';
import { AVAILABLE_COUNTRIES } from '../../../config';
import { CountryCodes } from '../../../data/countryDialCodes';
import { mapCountryDialCodeToCountryObject } from '../../utils/countryService';
import { useIntl } from 'react-intl';

const countryList = CountryCodes.map(c => {
  return {
    label: `${c.country} +${c.code}`,
    value: c.code
  };
});
const countryListIso = CountryCodes.map(c => {
  return {
    label: c.iso,
    value: c.code
  };
});

function CountryDialCodePicker({
  countryDialCode,
  setCountryDialCode,
  disabled,
  hideLabel,
  placeholder,
  showIso,
  selectorStyle = {},
  formWrapperStyle = {},
  labelStyle = {},
  labelClass = ''
}) {
  const countryObject = useMemo(() => {
    return mapCountryDialCodeToCountryObject(countryDialCode);
  }, [countryDialCode]);

  const intl = useIntl();

  return (
    <div style={{ marginLeft: '1rem', ...formWrapperStyle }}>
      {!hideLabel && (
        <label style={{ fontSize: '1.4rem', marginBottom: '0.8rem', ...labelStyle }} className={labelClass}>
          {intl.formatMessage({ id: 'user.country.placeholder' })}
        </label>
      )}
      <div>
        <Select
          isDisabled={disabled}
          styles={{
            control: styles => ({
              ...styles,
              ...selectorStyle,
              paddingBlock: '0.5rem'
            })
          }}
          placeholder={placeholder || 'Select country'}
          options={showIso ? countryListIso : countryList}
          value={countryObject}
          onChange={value => {
            setCountryDialCode(value?.value);
          }}
        />
      </div>
    </div>
  );
}

export default CountryDialCodePicker;
