import phone from 'phone';

export function getErrorPhoneNumber(phoneNumber, countryCode, intl) {
  // Check if phone starts with a leading 0
  if (!phoneNumber) return intl.formatMessage({ id: 'phone.required' });
  if (!countryCode) return intl.formatMessage({ id: 'country.code.required' });
  if (phoneNumber[0] === '0') {
    return intl.formatMessage({ id: 'phone.not.start.zero' });
  }

  const intlPhone = `+${countryCode}${phoneNumber}`;
  const result = phone(intlPhone);

  if (!result.isValid) {
    return intl.formatMessage({ id: 'phone.invalid' });
  }

  return null;
}
